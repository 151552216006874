<template>
   <div id="box">
      <h1>睡眠觉醒模式自评问卷</h1>
      <p>在每项问题中，请选出最能形容你在过去几星期中感受的句子：</p>
      <h4>1.如果你能够完全自由地计划白天的时间，你希望大约在什么时间起床?</h4>
      <el-radio-group v-model="radio">
         <el-radio label="5">A：早上 5 点至 6 点半 (05:00-06:30 h) </el-radio><br />
         <el-radio label="4">B：早上 6 点半至 7 点 45 分(06:30-07:45 h) </el-radio><br />
         <el-radio label="3">C：早上 7 点 45 分至 9 点 45 分(07:45-09:45 h) </el-radio><br />
         <el-radio label="2">D: 早上 9 点 45 分至 11 点 (09:45-11:00 h) </el-radio><br />
         <el-radio label="1">E：早上 11 点至正午 12 点 (11:00-12:00 h)</el-radio><br />
      </el-radio-group>
      <h4>2.如果你能够完全自由地计划夜晚的时间，你希望大约在什么时间去睡觉?</h4>
      <el-radio-group v-model="radio1">
         <el-radio label="5">A：晚上 8 点至 9 点(20:00-21:00 h) </el-radio><br />
         <el-radio label="4">B：晚上 9 点至 10 点 15 分(21:00-22:15 h) </el-radio><br />
         <el-radio label="3">C：晚上 10 点 15 分至 12 点半(22:15-00:30 h) </el-radio><br />
         <el-radio label="2">D：凌晨 12 点半至 1 点 45 分(00:30-01:45 h) </el-radio><br />
         <el-radio label="1">E：凌晨 1 点 45 分至 3 点 (01:45-03:00 h) </el-radio><br />
      </el-radio-group>
      <br />
      <h4>3.如果你要在早上的某个时刻起床，你会有多么依赖闹钟来唤醒你?</h4>
      <el-radio-group v-model="radio2">
         <el-radio label="4">A：完全不依赖 </el-radio><br />
         <el-radio label="3">B：略为依赖 </el-radio><br />
         <el-radio label="2">C：比较依赖 </el-radio><br />
         <el-radio label="1">D：非常依赖 </el-radio><br />
      </el-radio-group>
      <h4>4.在早上时，你有多容易起床?（当你没有被突如其来的事唤醒）</h4>
      <el-radio-group v-model="radio3">
         <el-radio label="1">A：非常困难 </el-radio><br />
         <el-radio label="2">B：比较困难 </el-radio><br />
         <el-radio label="3">C：一般容易</el-radio><br />
         <el-radio label="4">D：非常容易 </el-radio><br />
      </el-radio-group>

      <h4>5.早上起床后的半小时内，你有多精神?</h4>
      <el-radio-group v-model="radio4">
         <el-radio label="1">A：完全不精神 </el-radio><br />
         <el-radio label="2">B：一点精神</el-radio><br />
         <el-radio label="3">C：一般精神</el-radio><br />
         <el-radio label="4">D：非常精神</el-radio>
      </el-radio-group>
      <h4>6.在起床后的半小时内，你会感到有多饿?</h4>
      <el-radio-group v-model="radio5">
         <el-radio label="1">A：完全不饿</el-radio><br />
         <el-radio label="2">B：有一点饿 </el-radio><br />
         <el-radio label="3">C：一般饿 </el-radio><br />
         <el-radio label="4">D：非常饿</el-radio>
      </el-radio-group>
      <h4>7.清晨起床后的半小时内，你的感觉如何?</h4>
      <el-radio-group v-model="radio6">
         <el-radio label="1">A：非常疲倦</el-radio><br />
         <el-radio label="2">B：稍为疲倦</el-radio><br />
         <el-radio label="3">C：一般清醒 </el-radio><br />
         <el-radio label="4">D：非常清醒</el-radio>
      </el-radio-group>
      <h4>
         8.如果在第二天你没有任何约会，相比你平时习惯的时间，你会选择什么时间去睡觉?
      </h4>
      <el-radio-group v-model="radio7">
         <el-radio label="4">A：较平常推迟很少或从不推迟</el-radio><br />
         <el-radio label="3">B：较平常推迟不到一小时</el-radio><br />
         <el-radio label="2">C：较平常推迟 1-2 小时 </el-radio><br />
         <el-radio label="1">D：较平常推迟两小时以上</el-radio>
      </el-radio-group>
      <h4>
         9.假设你决定要开始做运动，你的朋友建议你应一周进行两次一小时的运动，而且在早上7-8点(07-08h)为最佳时间。请紧记你只需考虑自己的生理时钟，你认为你会表现得怎么样?
      </h4>
      <el-radio-group v-model="radio8">
         <el-radio label="4">A：很好的表现</el-radio><br />
         <el-radio label="3">B：一般好地表现</el-radio><br />
         <el-radio label="2">C：难以执行 </el-radio><br />
         <el-radio label="1">D：非常难以执行</el-radio><br />
      </el-radio-group>
      <h4>10.在夜晚你大约到什么时候你会感到疲倦，而且需要睡觉?</h4>
      <el-radio-group v-model="radio9">
         <el-radio label="5">A：晚上 8 点至 9 点 (20:00-21:00 h) </el-radio><br />
         <el-radio label="4">B：晚上 9 点至 10 点 15 分 (21:00-22:15 h) </el-radio><br />
         <el-radio label="3">C：晚上 10 点 15 分至 12 点 45 分(22:15-00:45 h) </el-radio><br />
         <el-radio label="2">D：凌晨 12 点 45 分至 2 点 (00:45-02:00 h) </el-radio><br />
         <el-radio label="1">E：凌晨 2 点至 3 点(02:00-03:00 h) </el-radio><br />
      </el-radio-group>
      <h4>
         11.假设你希望在一项会令你精神疲累而且需持续两个小时的测试中取得最佳表现时，如果你能完全自由地计划你的时间，仅需考虑你自己的生理时钟，你会选择以下哪段考试时间?
      </h4>
      <el-radio-group v-model="radio10">
         <el-radio label="6">A：早上 8 点至 10 点(08:00-10:00 h) </el-radio><br />
         <el-radio label="4">B：早上 11 点至下午 1 点(11:00-13:00 h) </el-radio><br />
         <el-radio label="2">C：下午 3 点至下午 5 点(15:00-17:00 h) </el-radio><br />
         <el-radio label="0">D：晚上 7 点至 9 点(19:00-21:00 h) </el-radio><br />
      </el-radio-group><br <h4>12.如果你要在晚上 11 点(23:00 h)去睡觉,你会有多疲倦?</h4>
      <el-radio-group v-model="radio11">
         <el-radio label="0">A：完全不疲倦 </el-radio><br />
         <el-radio label="2">B：小小疲倦 </el-radio><br />
         <el-radio label="3">C：一般疲倦</el-radio><br />
         <el-radio label="5">D：非常疲倦 </el-radio><br />
      </el-radio-group>
      <h4>
         13. 假设因为某些原因，你比平时迟几个小时去睡觉，但又不需在第二天
         早上的特定时间起床，你最可能出现以下哪种情况?
      </h4>
      <el-radio-group v-model="radio12">
         <el-radio label="4">A：按平常的时间起床，而且不会再睡</el-radio><br />
         <el-radio label="3">B：按平常的时间起床，但感到昏昏欲睡</el-radio><br />
         <el-radio label="2">C：按平常的时间起床，然后再睡 </el-radio><br />
         <el-radio label="1">D：较平常的时间迟起床</el-radio><br />
      </el-radio-group>
      <h4>
         14. 假设因为你要当夜更，而你要在清晨 4-6 点(04:00-06:00
         h)时候需要保持清醒，而第二天你没有任何约会。以下哪种情况最适合你?
      </h4>
      <el-radio-group v-model="radio13">
         <el-radio label="1">A：当夜更结束后才去睡觉</el-radio><br />
         <el-radio label="2">B：当夜更前片刻小睡，而结束后再睡觉 </el-radio><br />
         <el-radio label="3">C：当夜更前睡一觉，结束后再小睡 </el-radio><br />
         <el-radio label="4">D：只在当夜更前睡一觉</el-radio>
      </el-radio-group>
      <h4>
         15.
         假设你需要进行一项两小时的艰巨体力工作，你可以完全自由地计划时间，仅需考虑你自己的生理时钟，你会选择以下哪个时段?
      </h4>
      <el-radio-group v-model="radio14">
         <el-radio label="4">A：上午 8 点-10 点 (08:00-10:00 h) </el-radio><br />
         <el-radio label="3">B：上午 11 点-下午 1 点 (11:00-13:00 h) </el-radio><br />
         <el-radio label="2">C：下午 3 点-5 点 (15:00-17:00 h) </el-radio><br />
         <el-radio label="1">D：夜晚 7 点-9 点 (19:00-21:00 h) </el-radio><br />
      </el-radio-group>
      <h4>
         16.
         假设你决定要开始做运动，你的朋友建议你应一周进行两次一小时的运动，而且在晚上
         10-11 点(22:00-23:00
         h)为最佳时间。请紧记你只需考虑自己的生理时钟，你认为你会有怎么样的表现?
      </h4>
      <el-radio-group v-model="radio15">
         <el-radio label="1">A：很好的表现</el-radio><br />
         <el-radio label="2">B：一般的表现</el-radio><br />
         <el-radio label="3">C：难以执行 </el-radio><br />
         <el-radio label="4">D：非常难以执行</el-radio>
      </el-radio-group>
      <h4>
         17.假设你可以选择自己的工作时间，你每天只需工作 5 个小时(包括休息
         时间)，而这项工作是很有趣的，酬金会依据你的工作表现，你会选择
         以下哪个时段呢?
      </h4>
      <el-radio-group v-model="radio16">
         <el-radio label="5">A：五个小时，由早上 4 点至 8 点期间开始 (04:00-08:00 h) </el-radio><br />
         <el-radio label="4">B：五个小时，由早上 8 点至 9 点期间开始 (08:00-09:00 h) </el-radio><br />
         <el-radio label="3">C：五个小时，由早上 9 点至下午 2 点期间开始 (09:00-14:00 h)</el-radio><br />
         <el-radio label="2">D：五个小时，由下午 2 点至 5 点期间开始 (14:00-17:00 h) </el-radio><br />
         <el-radio label="1">E：五个小时，由下午 5 点至凌晨 4 点期间开始 (17:00-04:00 h)</el-radio><br />
      </el-radio-group>
      <h4>18.一天之中以下哪个时段是你的最佳时间?</h4>
      <el-radio-group v-model="radio17">
         <el-radio label="5">A：早上 5 点至 8 点 (05:00-08:00 h) </el-radio><br />
         <el-radio label="4">B：早上 8 点至 10 点 (08:00-10:00 h) </el-radio><br />
         <el-radio label="3">C：早上 10 点至下午 5 点 (10:00-17:00 h)</el-radio><br />
         <el-radio label="2">D：下午 5 点至 10 点 (17:00-22:00 h) </el-radio><br />
         <el-radio label="1">E：晚上 10 点至凌晨 5 点 (22:00-05:00 h)</el-radio><br />
      </el-radio-group>
      <h4>19. 人可分为“清晨”型和“夜晚”型,你认为你自己属于哪一类型?</h4>
      <el-radio-group v-model="radio18">
         <el-radio label="5">A：绝对“清晨”型 </el-radio><br />
         <el-radio label="4">B：“清晨”型多过“夜晚”型 </el-radio><br />
         <el-radio label="3">C：“夜晚”型多过“清晨”型 </el-radio><br />
         <el-radio label="2">D： 绝对“夜晚”型 </el-radio><br />
      </el-radio-group>
      <br>
      <el-button type="success"
                 @click="ExamSolutionADD"
                 :disabled="isdisabled">提交</el-button>
   </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
   mounted () {
      this.get();
   },
   updated () { },
   methods: {
      async ExamSolutionADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=睡眠觉醒MEQ&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.MEQText}&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         if (data.data.data == true) {
            // 存储MEQ的分数到本地存储
            const MEQScore = this.total;
            localStorage.setItem("MEQ", JSON.stringify(MEQScore));
            Message.success("提交成功，进入下一个量表评估");
            this.$router.push("/Assessment");
         }
      },
      //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
      get () {
         const patientN = localStorage.getItem("patientN");
         const patientI = localStorage.getItem("patientI");
         this.name = JSON.parse(patientN);
         this.cid = JSON.parse(patientI);
      },
   },
   computed: {
      MEQText () {
         if (this.total < 23) {
            return "分数不正常，请重新评估";
         }
         if (this.total >= 23 && this.total <= 26) {
            return "绝对 “夜晚”型";
         }
         if (this.total >= 27 && this.total <= 30) {
            return "绝对 “夜晚”型";
         }
         if (this.total >= 31 && this.total <= 34) {
            return "中度 “夜晚”型";
         }
         if (this.total >= 35 && this.total <= 38) {
            return "中度 “夜晚”型";
         }
         if (this.total >= 39 && this.total <= 41) {
            return "中度 “夜晚”型";
         }
         if (this.total >= 42 && this.total <= 45) {
            return "中间型";
         }
         if (this.total >= 46 && this.total <= 49) {
            return "中间型";
         }
         if (this.total >= 50 && this.total <= 53) {
            return "中间型";
         }
         if (this.total >= 54 && this.total <= 57) {
            return "中间型";
         }
         if (this.total >= 58 && this.total <= 61) {
            return "中度 “清晨”型";
         }
         if (this.total >= 62 && this.total <= 65) {
            return "中度 “清晨”型";
         }
         if (this.total >= 66 && this.total <= 69) {
            return "中度 “清晨”型";
         }
         if (this.total >= 70 && this.total <= 72) {
            return "绝对 “清晨”型";
         }
         if (this.total >= 72 && this.total <= 86) {
            return "中度 “清晨”型";
         }
      },
      isdisabled () {
         if (
            this.radio == "" ||
            this.radio1 == "" ||
            this.radio2 == "" ||
            this.radio3 == "" ||
            this.radio4 == "" ||
            this.radio5 == "" ||
            this.radio6 == "" ||
            this.radio7 == "" ||
            this.radio8 == "" ||
            this.radio9 == "" ||
            this.radio10 == "" ||
            this.radio11 == "" ||
            this.radio12 == "" ||
            this.radio13 == "" ||
            this.radio14 == "" ||
            this.radio15 == "" ||
            this.radio16 == "" ||
            this.radio17 == "" ||
            this.radio18 == ""
         ) {
            return true;
         }
         if (
            this.radio != "" ||
            this.radio1 != "" ||
            this.radio2 != "" ||
            this.radio3 != "" ||
            this.radio4 != "" ||
            this.radio5 != "" ||
            this.radio6 != "" ||
            this.radio7 != "" ||
            this.radio8 != "" ||
            this.radio9 != "" ||
            this.radio10 != "" ||
            this.radio11 != "" ||
            this.radio12 != "" ||
            this.radio13 != "" ||
            this.radio14 != "" ||
            this.radio15 != "" ||
            this.radio16 != "" ||
            this.radio17 != "" ||
            this.radio18 != ""
         ) {
            return false;
         }
      },
      total () {
         return (
            this.radio * 1 +
            this.radio1 * 1 +
            this.radio2 * 1 +
            this.radio3 * 1 +
            this.radio4 * 1 +
            this.radio5 * 1 +
            this.radio6 * 1 +
            this.radio7 * 1 +
            this.radio8 * 1 +
            this.radio9 * 1 +
            this.radio10 * 1 +
            this.radio11 * 1 +
            this.radio12 * 1 +
            this.radio13 * 1 +
            this.radio14 * 1 +
            this.radio15 * 1 +
            this.radio16 * 1 +
            this.radio17 * 1 +
            this.radio18 * 1
         );
      },
   },
   data () {
      return {
         ETID: "E1",
         isShow: false,
         name: "",
         cid: "",
         radio: "",
         radio1: "",
         radio2: "",
         radio3: "",
         radio4: "",
         radio5: "",
         radio6: "",
         radio7: "",
         radio8: "",
         radio9: "",
         radio10: "",
         radio11: "",
         radio12: "",
         radio13: "",
         radio14: "",
         radio15: "",
         radio16: "",
         radio17: "",
         radio18: "",
      };
   },
};
</script>

<style lang="scss" scoped>
// @import "./style.scss";
.el-radio {
   margin: 10px;
}
body {
   background: rgb(220, 245, 179);
}
#box {
   width: 100%;
   margin: auto;
   text-align: left;
   margin-left: 30px;
   // background: rgb(206, 245, 175);
}
.label_color {
   color: rgb(202, 44, 207);
}
h1 {
   text-align: center;
}

h4 {
   color: rgb(140, 102, 245);
}
</style>
